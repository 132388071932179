/* You can add global styles to this file, and also import other style files */

//@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.css";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./ng-select.styles";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "primeicons/primeicons.css";

.form-control.form-control-solid.is-invalid {
  border-color: #f1416c;
}

.form-select-solid.is-invalid {
  border-color: #f1416c;
}

.p-component.is-invalid {
  border: solid 1px #f1416c;
}

.btn i.far {
    padding-right: 24px;
}

.wizard-label i.fa-2x, .btn i.fa-2x {
    font-size: 2em !important;
}

.btn i.fa-3x {
  font-size: 3em;
}

ngb-carousel:focus {
  outline: none;
}

ngb-datepicker-navigation-select>.custom-select {
  flex: 1 1 auto;
  font-size: .875rem;
  height: 1.85rem;
  padding: 0 .5rem;
  border: none !important;
}

// puts the google places autocomplete dropdown results above the bootstrap modal 1050 zindex.
.pac-container {
  z-index: 10051 !important;
}

.ribbon-label:hover {
    cursor: pointer;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //display: inline-block;
}

.border-right {
  border-right: 1px solid #EBEDF3;
}

[hidden] { display: none !important;}

.collapsible:not(.collapsed)>.ribbon-label>.rotate-90 {
  transform: rotateZ(90deg);
  transition: transform .3s ease;
}

#frameWrap {
  width: 100%;
  height: 500px;
}

.overlay-layer {
  z-index: 9999 !important;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

svg.connector:hover path {
  stroke: #f1416c;
  transition: all ease 0.3s;
}

svg.connector:hover circle {
  fill: #f1416c;
  transition: all ease 0.3s;
}

svg path {
  transition: all ease 0.3s;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.link {
stroke-width: 2px;
}

/* Chrome, Safari, Edge, Opera */
input.numeric-without-arrows::-webkit-outer-spin-button,
input.numeric-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.numeric-without-arrows[type=number] {
  -moz-appearance: textfield;
}

.node-rect {
  fill: white;
  stroke: black;
  stroke-width: 1.5;
  cursor: move;
}

.node-text {
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  text-anchor: middle;
  alignment-baseline: middle;
  pointer-events: none;
  /* Disable text selection
     from http://stackoverflow.com/questions/826782/css-rule-to-disable-text-selection-highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.link-line {
  stroke: black;
  stroke-width: 1.5;
}

/* Set the arrowhead size. */
.arrow {
  stroke-width: 1.5px;
}

.links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.nodes circle {
  stroke: black	;
  stroke-width: 0px;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
  width: 100% !important;
}

.dependents-info {
  background-color: white !important;
  border: none;
  margin-left: -5px;
}

.line-item-text {
  font-size: 1rem;
  font-weight: 400;
}

.readonly-item {
  border: 1px dashed #e4e6ef;
}

.readonly-item:hover {
  background: white !important;
}

#flow-editor-svg path{
  fill: none;
  pointer-events: all;
}

#flow-editor-svg path:hover {
  stroke: #f1416c;
  cursor: pointer;
}

.flow-step {
  border: solid 1px;
  border-color: rgba(200,200,200,1);
  height: 80px;
  width: 200px;
  border-top: 4px solid;
  border-top-color: rgba(0,120,212,1) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
  margin-bottom: 80px;
  //text-align: center;
  position: relative;
}

.flow-step:hover {
  background-color: #eff6fc;
  cursor: pointer;
}

.logic-indicator {
  width: 36px;
  height: 36px;
  top: 18px;
  right: 180px;
  z-index: 10;
  position: absolute;
  border-radius: 50%;
  border: solid 1px rgba(200,200,200,1);
  background-color: white;
  box-shadow: 0 0 8px 1px rgba(218, 218, 218,1);
}

.logic-indicator:hover{
  border-color: rgba(0,120,212,1) !important;
  background-color: #eff6fc;
}

.ribbon .ribbon-label {
  z-index: 0 !important;
}

.login-body {
  margin-left: 8%;
  margin-top: 23vh;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.modal-dialog-60pct-of-screen-width {
  .modal-dialog {
    max-width: 60% !important;
  }
}

.modal-dialog-75pct-of-screen-width {
  .modal-dialog {
    max-width: 75% !important;
  }
}

.modal-dialog-90pct-of-screen-width {
  .modal-dialog {
    max-width: 90% !important;
  }
}

.register-body {
  margin-left: 8%;
  //margin-top: -50px;
}

.custom-ng-select.is-invalid>.ng-select-container {
  border: 1px solid #f5325c !important;
}

/* ---- BEGIN: In–editor mention list --------------------------------------------------------------- */

.ck-mentions .mention__item {
  display: block;
  height: 45px !important;
}

.ck-mentions .mention__item img {
  border-radius: 100% !important;
  height: 36px !important;
  width: 36px !important;
}

.ck-mentions .mention__item span {
  margin-left: .5em;
}

.ck-mentions .mention__item.ck-on span {
  color: var(--ck-color-base-background);
}

.ck-mentions .mention__item .mention__item__full-name {
  color: hsl(0, 0%, 45%);
}

.ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
  color: hsl(0, 0%, 40%);
}

.ck-content .mention,
.mention {
  background-color: #e8f5fa !important;
  color: blue !important;
  //border: solid 1px red;
}

ckeditor.is-invalid .ck-editor {
  border: 1px solid #f5325c !important;
}

.ck-content .table table td, .ck-content .table table th {
  border: none !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.ck-balloon-panel {
  z-index: 99999 !important;
}

.menu-badge {
  padding: 0.25em 0.45em!important;
  font-size: 0.75rem!important;
}
.accordion-button:not(.collapsed) {
  color: #009ef7!important;
  background-color: #ffffff!important;
  box-shadow: inset 0 -1px 0 #eff2f5!important;
}
/* ---- END: In–editor mention list --------------------------------------------------------------- */


@media (min-width: 768px) {
  .register-wrapper {
    .register-body {
      margin-left: 15%;
    }
  }
}

@media (min-width: 768px) {
  .login-wrapper {
    .login-body {
      margin-left: 15%;
    }
  }
}

.modal-header .btn-close:focus,
.modal-header .btn-close:active {
  outline: none;
  box-shadow: none;
}

// @import "styles/borrower-portal-override.css";
// @import "styles/agent-portal-override.css";

.login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
  color: #8e9095;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 2px;

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.login-error-body {
  overflow: hidden;
  word-break: break-word;
}

.password-validation-overlay {
  &:before {
    content: none !important;
  }

  &:after {
    content: none !important;
  }

  margin-top: 0px !important;
}

.p-dropdown {
  border: none;
  width: 100%;
  .p-dropdown-label {
    padding: 0.75rem 3rem 0.75rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    font-family: Poppins, Helvetica, sans-serif;
    background-color: #f5f8fa;
    color: #5e6278 !important;
    transition: color 0.2s ease, background-color 0.2s ease;
    min-height: 42.5px;
  }
  .p-dropdown-trigger {
    background-color: #f5f8fa !important;
  }
}

.custom-popover .popover-header {
  font-size: 1.25rem; /* Adjust the size as needed */
  font-weight: 600; /* Adjust the weight as needed */
}

.icon-button {
  cursor: pointer; /* Changes the cursor to a hand on hover */
  border: none; /* Removes any default border */
  background: none; /* Removes any default background */
  padding: 0; /* Adjusts padding if necessary */
  font-size: inherit; /* Keeps the font size consistent */
}
