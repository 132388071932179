// ng-select style overrides

// This parent selector is required to override ng-select's default styles
ng-select.ng-select {
  $border-radius: 0.475rem;
  $background-color: #f5f8fa;

  &.ng-select-single .ng-select-container {
    height: 3.2rem;
    font-family: Poppins, Helvetica, sans-serif;

    // .form-select
    display: block;
    width: 100%;
    padding: 0.75rem 3rem 0.75rem 0.25rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 12px;
    border: 1px solid #e4e6ef;
    border-radius: $border-radius;
    //box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container .ng-input>input {
      padding-top: 0.25rem;
      padding-left: 0.25rem;
    }
  }

  .ng-arrow-wrapper {
    // .form-select's arrow icon is used instead of ng-select's default arrow icon
    display: none;
  }

  .ng-clear-wrapper {
    // Center vertically
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0.25rem;
    // Align to the right (leave a little space for the arrow icon)
    right: 2.5rem;
  }

  &.ng-select-solid .ng-select-container {
    // .form-select.form-select-solid
    background-color: $background-color;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .ng-dropdown-panel {
    overflow: hidden;
    border-radius: 0.75rem !important;
    background-color: $background-color;
    transform: translateY(0.4rem);

    .ng-dropdown-panel-items {
      .ng-option {
        background-color: transparent;

        &:hover {
          background-color: #e4e6ef;
        }
      }

      .ng-optgroup {
        font-weight: bold;
      }
    }

    // Disable hiding the top edge of the dropdown panel
    &.ng-select-bottom {
      border-top-color: #ccc;
    }
  }

  // Disable flattening the bottom corners of the select container
  &.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  // Disable the shadow effect appearing on selecting an option
  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: inherit;
    box-shadow: unset;
  }
}
